<script>
import Lottie from "@/components/widgets/lottie.vue";
import animationData from "@/components/widgets/rhvddzym.json";

export default {
  components: {
    lottie: Lottie
  },
  data() {
    return {
      defaultOptions: {
        animationData: animationData
      },
    }
  }
}
</script>

<template>
  <div class="auth-page-wrapper pt-5">
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
      <div class="bg-overlay" />
      <div class="shape">
        <svg version="1.1" viewBox="0 0 1440 120">
          <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
        </svg>
      </div>
    </div>
    <div class="auth-page-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center mt-sm-5 mb-4 text-white-50">
              <div>
                <router-link to="/" class="d-inline-block auth-logo">
                  <img src="@/assets/images/logos/ApoioBranco.png" alt="" height="100">
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card mt-4">
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">Esqueceu a senha?</h5>
                  <lottie class="avatar-xl" colors="primary:#0ab39c,secondary:#405189" :options="defaultOptions"
                    :height="120" :width="120" />
                </div>
                <div class="alert alert-borderless alert-warning text-center mb-2 mx-2" role="alert">
                  Digite seu email e enviaremos as intruções!
                </div>
                <div class="p-2">
                  <form>
                    <div class="mb-4">
                      <label class="form-label">
                        Email
                      </label>
                      <input type="email" class="form-control" id="email" placeholder="Coloque seu email">
                    </div>
                    <div class="text-center mt-4">
                      <button class="btn btn-success w-100" type="submit">
                        Enviar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div class="mt-4 text-center">
              <p class="mb-0">
                Espere, eu me lembro da minha senha...
                <router-link to="/auth/signin-basic" class="fw-semibold text-primary text-decoration-underline">
                  Entrar
                </router-link>
              </p>
            </div>

          </div>
        </div>
      </div>
    </div>

    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="mb-0 text-muted">
                {{new Date().getFullYear()}} © Apoio.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>